<template>
  <div class="shenbaobiao">
    <heade></heade>
    <div class="con_wrap">
      <div class="con_box">
        <h1>申报表</h1>
        <div class="form_box">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="80px"
            class="demo-ruleForm"
          >
            <el-row>
              <el-col :span="24">
                <el-form-item label="单位名称" prop="name">
                  <el-input v-model="ruleForm.name"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="24">
                <el-form-item label="地址邮编" prop="address">
                  <el-input v-model="ruleForm.address"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="8">
                <el-form-item label="领导姓名" prop="leaderName">
                  <el-input v-model="ruleForm.leaderName"></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="8">
                <el-form-item label="办公电话" prop="telephone" class="size">
                  <el-input v-model="ruleForm.telephone"></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="8">
                <el-form-item label="E-mail" prop="email">
                  <el-input v-model="ruleForm.email"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="8">
                <el-form-item label="传真" prop="fax">
                  <el-input v-model="ruleForm.fax"></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="8">
                <el-form-item label="手机" prop="mobilePhone" class="size">
                  <el-input v-model="ruleForm.mobilePhone"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <div class="text_name">
              <p>参与课题实验情况：</p>
            </div>

            <el-row>
              <el-col :span="24">
                <el-form-item
                  label-width="160px"
                  label="参与子课题方向"
                  prop="ziketiFX"
                >
                  <el-input v-model="ruleForm.ziketiFX"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="24">
                <el-form-item
                  label-width="160px"
                  label="参与机构范围"
                  prop="canyujigou"
                >
                  <el-input v-model="ruleForm.canyujigou"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <div class="text_name">
              <p>课题主要负责人基本情况：</p>
            </div>

            <el-row>
              <el-col :span="8">
                <el-form-item label="姓名" prop="fuzeren_name">
                  <el-input v-model="ruleForm.fuzeren_name"></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="8">
                <el-form-item label="电子邮箱" prop="mailBox" class="size">
                  <el-input v-model="ruleForm.mailBox"></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="8">
                <el-form-item label="职务" prop="post">
                  <el-input v-model="ruleForm.post"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="8">
                <el-form-item label="联系方式" prop="contact">
                  <el-input v-model="ruleForm.contact"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <div class="text_name">
              <p>
                地方基本情况简介(重点介绍参与课题研究单位分布、数量和已承担科研项目等)：
              </p>
            </div>
            <el-form-item>
              <el-input type="textarea" v-model="ruleForm.brief"></el-input>
            </el-form-item>
            <div class="text_name">
              <p>课题实验目标(地区根据具体情况，希望达到课题研究整体目标)：</p>
            </div>
            <el-form-item>
              <el-input type="textarea" v-model="ruleForm.target"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                class="btn"
                @click="submitForm('ruleForm')"
                >立即创建</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>
<script>
import "@css/ico.css";
import "@css/foot.css";
import heade from "@components/heade.vue";
import foot from "@components/foot.vue";
export default {
  components: {
    heade,
    foot,
  },

  data() {
    return {
      ruleForm: {
        name: "",
        address: "",
        leaderName: "",
        telephone: "",
        mobilePhone: "",
        fax: "",
        email: "",
        ziketiFX: "",
        canyujigou: "",
        fuzeren_name: "",
        post: "",
        mailBox: "",
        brief: "",
        target: "",
      },
      rules: {
        name: [{ required: true, message: "请输入单位名称", trigger: "blur" }],
        address: [{ required: true, message: "请输入地址", trigger: "blur" }],
        leaderName: [
          { required: true, message: "请输入领导姓名", trigger: "blur" },
        ],
        telephone: [
          { required: true, message: "请输入办公电话", trigger: "blur" },
          { pattern: /^[0-9*#-]*$/, message: "电话号码格式不正确" },
        ],
        mobilePhone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { max: 11, message: "手机号长度不正确", trigger: "blur" },
          {
            pattern:
              /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            message: "手机号码格式不正确",
          },
        ],
        contact: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { max: 11, message: "手机号长度不正确", trigger: "blur" },
          {
            pattern:
              /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            message: "手机号码格式不正确",
          },
        ],
        email: [
          { required: true, message: "请输入邮箱", trigger: "blur" },
          {
            pattern: /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
            message: "邮箱格式不正确",
          },
        ],
        mailBox: [
          { required: true, message: "请输入邮箱", trigger: "blur" },
          {
            pattern: /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
            message: "邮箱格式不正确",
          },
        ],
        fuzeren_name: [
          { required: true, message: "请输入姓名", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.$router.go(0);
      })();
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
  created: function () {
    document.title = this.$route.meta.title;
  },
};
</script>

<style>
.shenbaobiao .con_wrap {
  width: 100%;
  height: 1280px;
  background: url(../../assets/images/falvshengming.jpg);
  background-size: cover;
  background-position: center 0;
}
.shenbaobiao .con_box {
  width: 1324px;
  height: 927px;
  position: relative;
  top: 190px;
  margin: 0 auto;
}
.shenbaobiao h1 {
  width: 100%;
  font-size: 30px;
  color: rgb(139, 7, 7);
  font-weight: 700;
  font-family: "楷体";
  text-align: center;
  padding-top: 50px;
}
.form_box {
  padding: 20px 100px;
  box-sizing: border-box;
  margin: 0 auto;
}
.el-form-item__label {
  color: #000;
}

.el-input__inner {
  margin-top: 5px;
  height: 30px;
  border-radius: 30px;
}
.el-form-item {
  margin-bottom: 10px;
}
.dd {
  width: 500px;
}
.size {
  width: 350px;
  display: inline-block;
}
.padding {
  width: 100%;

  box-sizing: border-box;
}
.text_name {
  width: 100%;
  height: 39px;
}
.text_name > p {
  text-align: left;
  width: 100%;
  vertical-align: middle;
  float: left;
  font-size: 14px;
  color: #000;
  line-height: 40px;
  box-sizing: border-box;
}
.btn {
  margin-left: 40%;
}
</style>